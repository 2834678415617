.auth-main-container {
    height: 850px;
}

.auth-main-container-title {
    gap: 30px;
}
.auth-main-title {
    font-size: 60px;
}
.auth-main-description {
    font-size: 40px;
    text-align: center;
}

.auth-error-header {
    height: 300px;
}

.auth-error-footer-container {
    gap: 40px;
    flex-wrap: wrap;
    margin-bottom: 1vh;
}

.auth-error-footer-image {
    height: 87px;
    width: 350px;
    opacity: 1;
    pointer-events: auto;
}

.auth-error-footer-description {
    font-size: 40px;
    text-align: center;
}
